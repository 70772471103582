import { DateUtil } from '~/utils/date-util'
// 誕生日からY歳Mヶ月の文字列に変換する関数
// 引数：誕生日
// 返値：Y歳Mヶ月
export function ageFormatter (birthDate: Date) {
  const nowDate = new DateUtil().date
  let totalMonths = getMonthDiff (birthDate, nowDate)
  // 誕生月が現在月と同じかつ誕生日が来ていない場合は、1か月減らす
  if ((birthDate.getMonth() == nowDate.getMonth()) && (birthDate.getDate() > nowDate.getDate())) {
    totalMonths = totalMonths - 1
  }
  const fraction = totalMonths % 12
  return ((totalMonths - fraction) / 12).toString() + "歳" + fraction.toString() + "ヶ月"
}

// 2つのDateオブジェクトの間の月数を計算する
export function getMonthDiff (start: Date | string, end: Date | string) {
  const startDate = new DateUtil(start).date
  const startMonths = startDate.getFullYear() * 12 + startDate.getMonth()

  const endDate = new DateUtil(end).date
  const endMonths = endDate.getFullYear() * 12 + endDate.getMonth()

  return endMonths - startMonths
}

// 誕生日から年齢を算出する関数
// 引数：誕生日
// 返値：年齢
export function getAge (birthDate: Date) {
  const nowDate = new DateUtil().date
  let totalMonths = getMonthDiff (birthDate, nowDate)
  // 誕生月が現在月と同じかつ誕生日が来ていない場合は、1か月減らす
  if ((birthDate.getMonth() == nowDate.getMonth()) && (birthDate.getDate() > nowDate.getDate())) {
    totalMonths = totalMonths - 1
  }
  const fraction = totalMonths % 12
  return (totalMonths - fraction) / 12
}
